<template>
  <div>
    <CRow>
      <CCol class="text-right mt-2">
        <div class="float-right">
          <div class="float-left"  @click="onChangeEdit">
            <CIcon  size="lg" name="cil-pencil" class="text-white m-1"/>
          </div>
          <div class="float-left" >
            <sharing :sharing="sharing"/>
          </div>
        </div>
      </CCol>
    </CRow>
    <CRow>
<!--      <div style="width: 100%; height: 300px; overflow: hidden">-->
<!--        <img style="object-fit: cover;" width="100%" src="https://thumbs.dreamstime.com/z/fintech-blockchain-technology-info-graphic-thin-line-art-style-design-web-banner-business-startup-commercial-poster-design-90578527.jpg"/>-->
<!--      </div>-->

      <div class="c-avatar"  style="height: 240px; width: 240px; margin: auto;">
        <img :src="imageProfile" @error="setAltImg"  class="c-avatar-img " width="100%" height="100%" />
        <div class="position-absolute c-avatar bg-white" style="right: 15%; bottom:15%">
          <CIcon class="text-dark" size="lg" name="cil-camera"/>
        </div>
      </div>
    </CRow>
    <CRow>
      <CCol col="12" class="text-center">
        <h4 v-if="!isEdit">{{displayName}}</h4>
        <CInput
            v-if="isEdit"
            v-model="displayName"
            :horizontal="{label: 'col-sm-12', input: 'col-sm-12'}"
            placeholder="DisplayName"
            autocomplete="username"
        />
      </CCol>
    </CRow>

    <CRow >
      <CCol col="12">
        <h5> About </h5>
      </CCol>
      <CCol col="12">
<!--        <h6 v-if="!isEdit" >-->
<!--          Slumdoge Billionaire NFTS have been algorithmically bred by AI rumoured to be stolen from one of Elon’s top engineers, or maybe it was Greg. Starting out with 19 base Doge breeds and over 200 unique traits there were over a billion possible combinations of traits but only 10,000 will ever make it to existence. Each Slumdoge Billionaire NFT is verifiably one-of-a-kind and the random nature of the trait assignment has led to a series of collectibles as unique and diverse as the Doge faithful themselves. From super rare traits to the most aesthetic woofer in town there’s a Slumdoge for every taste packed with pop culture references, influenced equally by movies, internet culture and streetwear and are 100% pandemic-chic.-->
<!--        </h6>-->

        <div v-if="!isEdit" v-html="decription"></div>
        <quill-editor v-if="isEdit" class="mb-3" :content="decription" @change="onEditorDecription" />
      </CCol>
    </CRow>

    <CRow v-if="isEdit">
      <CCol></CCol>
      <CCol class="text-right">
        <CButton color="light" class="text-white w-25 mr-2">Cancel</CButton>
        <CButton color="success" class="text-white w-25" @click="onSubmit">Submit</CButton>
      </CCol>
    </CRow>

    <CRow class="mt-4" v-if="!isEdit">
      <CCol>
        <CTabs :active-tab="0" fill >

          <CTab title="Assets">
            <CCard>
              <CCardBody>
                <CRow>
                  <CCol sm="3"  v-for="(item, index) in itemAssets" v-bind:key="index">
                    <ItemAssets :item="item" :market="true" :onwer="true" />
                  </CCol>
                </CRow>
              </CCardBody>
            </CCard>
          </CTab>
          <CTab title="Market">
            <CCard>
              <CCardBody>
                <CRow>
                  <CCol sm="3"  v-for="(item, index) in itemMarketByOnwer" v-bind:key="index">
                    <!--                    <ItemMarkets :item="item" :onwer="false" />-->
                    <ItemMarkets :item="item" :market="false" :onwer="true" />
                  </CCol>
                </CRow>
              </CCardBody>
            </CCard>
          </CTab>

          <CTab title="Historys">
          </CTab>
<!--          <CTab title="History ">-->
<!--            Text will not be shown.-->
<!--          </CTab>-->
        </CTabs>
      </CCol>
    </CRow>

    <CRow class="mt-3">
      <CCol>
        <CButton color="success"  class="pr-4 pl-4 text-white" shape="pill" variant="outline" > Create Collection </CButton>
      </CCol>
    </CRow>


  </div>
</template>

<script>

  import {mapGetters} from 'vuex'

  import Vue from 'vue'
  import VueQuillEditor from 'vue-quill-editor'
  import 'quill/dist/quill.core.css'
  import 'quill/dist/quill.snow.css'
  import 'quill/dist/quill.bubble.css'
  import Sharing from "@/project/views/util/sharing";
  import ItemMarkets from "@/project/views/util/ItemMarkets";
  import ItemAssets from "@/project/views/util/ItemAssets";
  import {address721} from "@/store/modules/Metamask/constants/escrowContract";
  Vue.use(VueQuillEditor)
export default {
  name: 'Banks',
  components: {Collection, ItemAssets, ItemMarkets, Sharing},
  data: function () {
    return {
      image: "",
      isEdit: false,
      imageProfile:"",
      displayName:"",
      decription:"",
      sharing: {
        url: 'https://news.vuejs.org/issues/180',
        title: 'Say hi to Vite! A brand new, extremely fast development setup for Vue.',
        description: 'This week, I’d like to introduce you to "Vite", which means "Fast". It’s a brand new development setup created by Evan You.',
        quote: 'The hot reload is so fast it\'s near instant. - Evan You',
        hashtags: 'vuejs,vite,javascript',
        twitterUser: 'youyuxi'
      },
    }
  },
  mounted () {

  },

  created () {
    this.onConnectMataMaskAddress();
  },

  beforeDestroy () {

  },

  methods: {
    onChangeEdit(){
      this.isEdit = !this.isEdit
    },
    onEditorDecription(html, text, quill) {
      this.decription = html.html;
    },


    onConnectMataMaskAddress(){
      this.$store.dispatch('metamask/onMetaMaskAddress')
    },

    onGetProfile(){
      var body = {};
      body.metamask = this.$store.getters['metamask/onGetMetaMaskAddress'];
      this.$store.dispatch('gateway/onProfile',body)
    },
    onSubmit(){
      var obj = {}
      obj.metamask = this.$store.getters['metamask/onGetMetaMaskAddress'];
      obj.displayName = this.displayName;
      obj.decription = this.decription;

      this.$store.dispatch('gateway/onUpdateProfile',obj)

    },

    uploadImage(e) {
      this.image = e.target.files[0];
      const image = e.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = e => {
        this.url = e.target.result;
        this.uploadImageState = true;
      };
    },

    onUpdateImageProfile(){
      // let data = new FormData();
      // data.append('image', this.image, this.image.fileName);
      // this.$store.dispatch('gateway/onUpdateProfileImage',data)
    },

    onConnectFacebook(){

    },



    //
    onMarketByOnwer(){
      var info = JSON.parse(localStorage.getItem('info'))

      var obj = {};
      obj.onwer = info._id;
      this.$store.dispatch("gateway/onMarketsByOnwer", obj)
    },
    onNFTsByOnwer(){
      var info = JSON.parse(localStorage.getItem('info'))

      var obj = {};
      obj.onwer = info._id;
      this.$store.dispatch("gateway/onNFTsByOnwer", obj)
    },

    onView(){
      this.$router.push('/home/market')
    },
    setAltImg(event) {
      event.target.src = "img/avatars/0.jpeg"
    }
  },

  computed:{
    ...mapGetters({
      metamask:'metamask/onGetMetaMaskAddress',
      itemAssets:'gateway/objNfts',
      itemMarketByOnwer:'gateway/objMarketByOnwer',
      profile:'gateway/objProfile',
      reload: 'eRCMarketPlace/objReload'

    })
  },

  watch: {
    metamask(value){
      this.onGetProfile();
    },
    profile(value){
      this.imageProfile = (value.userinfo.image_profile == null)?"":value.userinfo.image_profile;
      this.displayName = value.displayName;
      this.decription = value.bio.about;

      this.onMarketByOnwer();
      this.onNFTsByOnwer();
    },
    reload(value){
      console.log(value);
      this.onMarketByOnwer();
      this.onNFTsByOnwer();
    }
  }
}
</script>
