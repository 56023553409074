<template>
  <CCard accent-color="success"  >
    <CCardBody class="p-0">
<!--      <item-nfts :item="item" :market="market" @click="onInfoMation"/>-->
      <div class="position-relative " style="height:24em; overflow: auto" @click="onInfoMation">
        <div class="overflow-auto m-auto text-center" >
          <img v-if="item.fullyFommercialRight" class="position-absolute text-success" style="right: 10px; top:10px" width="40px" height="40px" src="@/assets/logo/copyright.png">
          <img class="w-100" :src="item.image" />
        </div>

      </div>
      <div class="p-3">
        <CRow>
          <CCol col="12" style="white-space: nowrap; text-overflow: ellipsis; overflow: hidden;">
            <h5 class="text-nowrap font-weight-bold"> {{ item.name }}</h5>
          </CCol>
        </CRow>
        <CRow>
          <CCol style="white-space: nowrap; text-overflow: ellipsis; overflow: hidden;"><span class="font-weight-bold"> Category  </span></CCol>
          <CCol class="text-right" style="white-space: nowrap; text-overflow: ellipsis; overflow: hidden;"><span class="text-white">{{item.categorie.title}}</span></CCol>
        </CRow>
        <CRow>
          <CCol><span class="font-weight-bold" style="white-space: nowrap; text-overflow: ellipsis; overflow: hidden;" > {{ (item.bidding == true)?"BID ":"Price" }}   </span></CCol>
          <CCol class="text-right" style="white-space: nowrap; text-overflow: ellipsis; overflow: hidden;"><span class="text-white" >{{item.price}} {{ (item.bidding == true)?"WBNB":"BNB"}}</span></CCol>
        </CRow>
        <CRow>
          <CCol col="4">
            <div class="c-avatars-stack mt-2">
              <!--            <div class="c-avatar c-avatar-xs" v-c-tooltip.hover.click="'Creator : '+item.creator.displayName">-->
              <!--              <img-->
              <!--                  class="c-avatar-img"-->
              <!--                  :src="item.creator.userinfo.image"-->
              <!--                  @error="setAltImg"-->
              <!--                  alt="admin@bootstrapmaster.com"-->
              <!--                  loop=infinite-->
              <!--              >-->
              <!--            </div>-->
              <div class="c-avatar c-avatar-xs" v-c-tooltip.hover.click="'Onwer '+item.onwer.displayName">
                <img
                    :src="item.onwer.userinfo.image"
                    width="40px" height="40px" style="border-radius: 20px; "
                    @error="setAltImg"
                    alt="admin@bootstrapmaster.com"
                    loop=infinite
                >
              </div>
            </div>
          </CCol>

          <CCol class="text-right mt-2" style="white-space: nowrap; text-overflow: ellipsis; overflow: hidden;">
            <div class="float-right">
              <CButton color="transparent p-0" class="float-left mr-2"  @click="onLike">
                <span class="mr-2 text-white">{{(item.like != null)?item.like.length:0}}</span>
                <CIcon  size="lg" name="cil-heart" class="text-white"/>
              </CButton>
<!--              <div class="float-left" >-->
<!--                <sharing :sharing="item.sharing"/>-->
<!--              </div>-->
            </div>
          </CCol>
        </CRow>

      </div>
    </CCardBody>
  </CCard>
</template>

<script>

import Sharing from "@/project/views/util/sharing";
import FlipCountdown from "@/project/containers/utils/FlipCountdown";
import ItemNfts from "@/project/views/util/ItemNfts";
import {mapGetters} from "vuex";
import store from "@/store/store";
export default {
  name: 'ItemMarkets',
  components: {ItemNfts, FlipCountdown, Sharing},
  props:{
    item: {},
    onwer: {
      type: Boolean,
      default: false
    },
    market: {
      type: Boolean,
      default: false
    }
    // sharing: {}
  },
  data: function () {
    return {
      isApprove:false,
      isCreateOrder:false
    }
  },
  mounted () {

  },

  created () {

  },

  beforeDestroy () {

  },

  methods: {
    onSetSharing(){
      this.sharing = {
        url: 'https://news.vuejs.org/issues/180',
        title: this.name,
        description: this.description,
        quote: this.priceInWen+"NEXT/USDT" ,
        hashtags: 'nextart,nft,bsc',
    // twitterUser: 'youyuxi'
      }
    },
    setAltImg(event) {
      // event.target.src = "https://i.pinimg.com/originals/e9/34/47/e934478b9d5a4753bda195db8fb5eb8b.gif"
      event.target.src = "img/token/nextart,png"
    },
    onLike(){
      this.$emit('update:value', this.item)
    },
    onInfoMation(){
      this.$emit('infomation:value', this.item)
    },

    // market contact //
    onCheckIsApprove(){
      var dateTime = new Date(this.item.expiresAt).getTime() / 1000;
      if(this.item.priceInWen >= 0 && dateTime > 0){
        this.isApprove = true;
      }
    },
    onApprove(){

      this.$store.commit("eRCMarketPlace/setApproveNFT",this.item.tokenId)
      // var obj = {};
      // obj.address = this.$store.getters['erc721/onGetMetaMaskAddress'];
      // obj.tokenId = this.$store.getters['erc721/objTokenId'];
      // this.$store.dispatch('erc721/onApprove', obj);
      //
      // this.isCreateOrder = true;
    },

    onCreateOrder(){

    },

    onCancelOrder(){

    },
    // end market contact //
  },


  computed: {
    ...mapGetters({
      // isApproveId: "eRCMarketPlace/objApproveNFT"
    })
  },

  watch: {
    'item.priceInWen'(value){
      this.onCheckIsApprove();
    },
    'item.expiresAt'(value){
      this.onCheckIsApprove();
    },
    isApproveId(value){
      if(value == this.item.tokenId){
        this.isCreateOrder = true
      }
    }

  }
}
</script>
